.breadcrumb-link,
.breadcrumb-link:hover,
.breadcrumb-link:disabled {
  border-color: var(--gray-text-color);
  color: var(--gray-text-color);
  padding: 0.5rem 0rem;
  position: relative;
  height: unset;
}

.breadcrumb-link.active {
  border-color: var(--bs-primary);
  color: var(--bs-primary);
}

.breadcrumb-link:after {
  background-color: var(--gray-text-color);
  content: " ";
  display: block;
  width: 0.6rem;
  height: 0.6rem;
  position: absolute;
  left: 50%;
  bottom: -0.3rem;
  border-radius: 0.3rem;
}

.breadcrumb-link.active:after {
  background-color: var(--bs-primary);
}
