.sku-composition-tree-container {
  ul {
    padding-left: 1rem !important;
  }

  ul li {
    position: relative;
    list-style-type: none;
    margin-bottom: 0.5rem;
  }

  ul li:not(.remove-tree-link):before {
    position: absolute;
    left: -10px;
    top: 0px;
    border-left: 2px solid var(--gray-text-color);
    border-bottom: 2px solid var(--gray-text-color);
    content: "";
    width: 20px;
    height: 16px;
  }

  ul li:not(.remove-tree-link)::after {
    position: absolute;
    left: -10px;
    top: 0.5rem;
    border-left: 2px solid var(--gray-text-color);
    content: "";
    width: 20px;
    height: 100%;
  }

  ul li:last-child::after {
    display: none;
  }

  /* This removes the top vertical line of the first child in the root */
  ul.root > li:first-child::before {
    border-left: 0;
  }

  /* This adjusts the bottom vertical line of the first child in the root */
  ul.root > li:first-child::after {
    top: 15px;
  }

  /* This removes the horizontal line when there is only one child in the root */
  ul.root.only-child > li:first-child::before {
    border-bottom: 0;
  }

  /* This extends the top vertical bar of all the first childs that are not in the root */
  ul:not(.root) > li:first-child::before {
    top: -8px;
    height: 25px;
  }

  /* Styles for a custom expandable section look */
  .accordion {
    box-shadow: none !important;
  }

  .accordion-item {
    border: 0;
    background-color: transparent;
  }

  .accordion-header {
    border: 1px solid #d1d3e2;
    border-radius: 0.375rem;
  }

  .accordion-button,
  .accordion-body {
    padding: 0.75rem 0.75rem;
  }

  .component-group-container {
    .accordion-body {
      padding: 0 !important;
      box-shadow: 0 0.125rem 0.25rem 0 rgba(58, 59, 69, 0.2) !important;
    }
  }

  /* Styles for Action Buttons */
  .space-holder {
    min-width: 115px;
    min-height: 40px;
    visibility: hidden;
  }

  .checkbox-space-holder {
    min-width: 10px;
    min-height: 40px;
    visibility: hidden;
  }

  .tree-actions-container {
    min-width: 115px;
  }

  /* Styles for the Components Table 
    table,
    td {
        padding: 5px;
        border: 0;
        border-collapse: collapse;
    }

    table {
        width: 100%;
    }

    a {
        display: inline-block;
        height: 40px;
        width: 40px;
        border-radius: 2px;
        background: lightblue;
        text-decoration: none;
        text-align: center;
        line-height: 40px;
    }

    table td {
        border: 1px solid #000;
    }

    table td:last-child {
        width: 40px;
        border: 0;
        background-color: white;
    }
    */
}
