:root {
  --black-text-color: #272727;
  --gray-text-color: #858796;
  --red-text-color: #bb0000;
  --bs-primary: #4e73df;
  --input-fields-height: 30px;
}

#wrapper {
  min-height: 100vh;
  max-height: 100vh;
}

/*PIP Layout Switch styles*/
.react-switch-checkbox {
  height: 0;
  width: 0;
  visibility: hidden;
}

.react-switch-label {
  align-self: center;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 50px;
  height: 25px;
  background: var(--gray-text-color);
  border-radius: 100px;
  position: relative;
  transition: background-color 0.1s;
}

.react-switch-label .react-switch-button {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  width: 25px;
  height: 25px;
  border-radius: 45px;
  transition: 0.15s;
  background: #fff;
  box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
}

.react-switch-checkbox:checked + .react-switch-label {
  background-color: var(--bs-primary);
}

.react-switch-checkbox:checked + .react-switch-label .react-switch-button {
  left: calc(100%);
  transform: translateX(-100%);
}

.react-switch-label:active .react-switch-button {
  width: 25px;
}
/*===============================================================*/

#content-wrapper {
  background-color: white !important;
}

#main-content {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.topbar {
  height: 3rem !important;
}

.row {
  margin-left: unset !important;
  margin-right: unset !important;
}

.form-control {
  color: var(--black-text-color) !important;
  height: var(--input-fields-height);
  padding: 2px 8px;
}

.form-label {
  margin-bottom: 0 !important;
  font-weight: bold;
}

.shadow {
  box-shadow: 0 0.1rem 0.2rem 0 rgba(58, 59, 69, 0.15) !important;
}

/* Buttons */
.btn {
  padding-bottom: 0px;
  padding-top: 0px;
  height: 30px;
}

.icon-modal-start-button {
  height: 20px;
}

/* Textareas */
textarea.form-control {
  min-height: 30px;
}

textarea::-webkit-scrollbar {
  height: 5px;
}

::-webkit-scrollbar-thumb {
  background-color: #c1c1c1;
  border-radius: 5px;
}

.horizontal-scroll {
  overflow-y: hidden;
  overflow-x: auto;
  white-space: nowrap;
}

/* #wrapper #content-wrapper {
  overflow-x: unset;
} */

/* ----Filter styles */
.form-group {
  margin-bottom: 0.25rem !important;
}

.form-group .fixed-checkbox-height {
  height: 30px;
  display: flex;
  align-items: center;
}

.form-group.inventory-col .react-select__clear-indicator {
  padding: 8px 0;
}

.form-group.inventory-col .react-select__single-value {
  overflow: visible;
}

/* Select Custom Styles */
.accordion {
  --bs-body-color: unset;
  --bs-accordion-color: unset;
  --bs-border-color: #d1d3e2;
  border-radius: 0.35rem;
  --bs-accordion-btn-icon-transform: unset;
}

.accordion-body {
  padding: 0.5rem 0.75rem !important;
}

.accordion-button {
  color: #4e73df !important;
  background-color: #f8f9fc !important;
  padding: 0.25rem 0.5rem !important;
}

.accordion-button::after {
  transform: unset;
  font-family: "Font Awesome 5 Free";
  background-image: none;
  color: #cccccc;
  font-weight: 900;
  content: "\f078";
}

.accordion-button:not(.collapsed)::after {
  background-image: none;
  content: "\f054";
}

/* Select Custom Styles */
.react-select__control {
  font-size: 1rem !important;
  font-weight: 400 !important;
  line-height: 1.5 !important;
  color: var(--gray-text-color) !important;
  border: 1px solid #d1d3e2 !important;
  border-radius: 0.35rem !important;
  /* height: var(--input-fields-height) !important; */
  min-height: 20px !important;
}

.react-select__control--is-focused,
.react-select__control--menu-is-open {
  color: var(--gray-text-color) !important;
  border-color: #bac8f3 !important;
  outline: 0 !important;
  box-shadow: 0 0 0 0.2rem rgb(78 115 223 / 25%) !important;
  /* height: var(--input-fields-height) !important; */
}

.react-select__input-container {
  margin: 0px !important;
}

.react-select__option,
.react-select__option--is-focused {
  color: var(--black-text-color) !important;
}

.react-select__value-container {
  padding: 0px 8px !important;
}

.react-select__option--is-selected {
  color: #fff !important;
}

.react-select__indicator-separator {
  display: none;
}

.react-select__indicators {
  height: 20px;
  margin: auto;
}

.react-select__control--is-disabled {
  background-color: #eaecf4 !important;
}

.react-select__single-value--is-disabled {
  color: var(--black-text-color) !important;
}

.year-select .react-select__control {
  background-color: #fff !important;
  margin: 0 5px !important;
  width: 188px;
}

.active-search-string {
  background-color: transparent;
  padding: 0;
  color: var(--black-text-color);
  font-weight: 700;
}

/* Date Picker and Release Date Operator */
.release-date-operator {
  min-width: 78px;
}

/* ----Table styles */
.table-dropdown-filter {
  border: var(--ag-borders-input) var(--ag-input-border-color);
  color: var(--ag-header-foreground-color);
}

.table-top-search {
  color: var(--black-text-color);
}

.table-top-container {
  color: var(--black-text-color);
}

.table-top-input {
  color: var(--black-text-color) !important;
  border: 1px solid #d1d3e2 !important;
  border-radius: 0.35rem !important;
  background-color: #fff;
  padding: 3px 5px;
  margin: 0 5px;
}

.table-top-input:focus {
  border-color: #bac8f3 !important;
  outline: 0 !important;
  box-shadow: 0 0 0 0.2rem rgb(78 115 223 / 25%) !important;
}

.ag-cell-value,
.ag-cell-wrapper {
  min-height: 100%;
}

.ag-cell-wrap-text,
.ag-header-cell-text {
  word-break: normal;
}

.sku-composition-components-table {
  .highlight-cell {
    background-color: #ffee06;
  }
}

.card-body {
  padding: 0.5rem;
}

.card-header {
  background-color: #f8f9fc;
  padding: 0.5rem;
}

.icon-button {
  padding: 0;
  background-color: transparent;
  border: none;
  color: unset;
}

.icon-button svg {
  color: var(--gray-text-color);
}

.icon-button {
  padding: 0;
  background-color: transparent;
  border: none;
  color: unset;
}

.icon-button:disabled svg,
.icon-button[disabled] svg {
  color: #e3e6f0;
}

.icon-button.close-cross {
  height: max-content;
}

.icon-button.upload-button {
  max-width: 60px;
}

.icon-button.upload-button svg {
  /* color: #000; */
  height: 90%;
}

.ag-paging-panel {
  border: none;
  justify-content: space-between;
}

.ag-root-wrapper {
  border: none;
}

.ag-root-wrapper-body {
  border: 2px solid #e3e6f0;
}

.ag-header-group-cell-label,
.ag-header-cell-label {
  justify-content: space-between;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.01);
}

.table {
  color: var(--gray-text-color);
}

.table-striped > tbody > tr > * {
  color: var(--black-text-color) !important;
  padding: 0rem 0.5rem;
  vertical-align: middle;
  line-height: 20px;
}

.ag-theme-alpine {
  --ag-line-height: 20px;
  --ag-foreground-color: var(--black-text-color);
  --ag-foreground-font-size: 20px;
  --ag-header-cell-horizontal-border: solid;
  --ag-cell-horizontal-border: solid #e3e6f0;

  --ag-header-foreground-color: var(--gray-text-color);
  --ag-header-background-color: #fff;
  --ag-odd-row-background-color: rgba(0, 0, 0, 0.05);
  --ag-header-column-resize-handle-color: #f5f5f5;

  --ag-header-column-separator-display: block;
  --ag-header-column-separator-height: 100%;
  --ag-header-column-separator-width: 1px;

  --ag-border-color: #e3e6f0;
  --ag-borders: 1px solid;

  --ag-grid-size: 5px;
  --ag-row-height: calc(var(--ag-grid-size) * 5);
  --ag-header-height: calc(var(--ag-grid-size) * 6);
  --ag-cell-horizontal-padding: calc(var(--ag-grid-size) * 1);

  --ag-font-size: 15px;
  --ag-font-family: "Nunito", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --ag-font-color: var(--black-text-color);
  --ag-checkbox-border-radius: 8px;
}

.ag-header-cell-text {
  font-weight: 800;
}

.table th {
  padding: 0rem 0.5rem;
}

.table td,
.table tbody th {
  padding: 0.5rem;
}

.composition-search-sku-field {
  height: 30px;
}

/*PIP Styles===================================*/
.black-text {
  color: var(--black-text-color);
}

.gray-text {
  color: var(--gray-text-color);
}

.red-text {
  color: var(--red-text-color);
}

.top-nav-product-search {
  .small-spinner-spacing {
    margin-right: 28px;
  }

  .top-nav-product-search-field {
    max-width: 100px;
  }

  .small-spinner-container {
    position: absolute;
    right: 0;
  }
}

.pip-tabs-header {
  background-color: #e3e6f0 !important;
}

.product-information-tabs-container .tab-content {
  max-height: 85vh;
  overflow-y: overlay;
  border-top: 1px solid var(--black-text-color);
}

.product-information-page-container .form-control {
  color: var(--black-text-color);
}

.product-information-page-container {
  display: flex;
  flex-direction: column;
  height: 100%;

  .table {
    margin-bottom: 0;
  }
}

.main-container {
  top: 0;
  /*position: sticky;*/
  /*TODO: Understand how can i mantain the position=sticky but the modals to show correctly*/
  background-color: #fff;
  z-index: 0;
  /*TODO: Understand how can i mantain the z-index=10 but the modals to show correctly*/
  border-radius: 6px;
  padding: 5px;

  &.unset-position {
    position: unset;
  }
}

.left-section {
  flex-basis: 90%;
  min-width: 250px;
}

.right-section {
  align-self: center;
}

.left-section .form-label {
  max-width: max-content;
  align-self: center;
  /* min-width: 105px; */
}

.field-container label {
  align-self: unset !important;
}

.second-header-field {
  min-width: 155px !important;
}

.img-container {
  max-width: 180px;
  min-width: 150px;
  max-height: 125px;
}

.img-placeholder {
  width: 150px;
  height: 130px;
  background-color: #eaecf4;
  border-radius: 10px;
  border: 1px solid #d1d3e2;
}

.img-container img {
  max-width: 100%;
  height: auto;
  object-fit: contain;
  cursor: pointer;
}

.send-t25-button {
  width: 120px;
}

.table-title {
  background-color: #f8f9fc !important;
  border-color: #f8f9fc;
  color: var(--bs-primary);

  border-radius: 6px;
}

.link-srp {
  font-size: small;
  text-decoration-color: #4e73df;
}

.link-components,
.link-pp {
  text-decoration-color: #4e73df;
}

.tooltip-text-box {
  background-color: white !important;
  color: var(--black-text-color) !important;
  border: 1px solid #2e59d9 !important;
  border-radius: 10px !important;
  opacity: 100% !important;
  z-index: 1;
}

.no-resize {
  resize: none;
}

.checkbox-field {
  width: 100%;
  min-width: 55px;
}

.checkbox-label {
  min-width: fit-content;
  margin-bottom: 0;
  font-weight: bold;
}

/*Create New PIS Styles===================================*/

.label-3 {
  text-align: center;
}

.bottom-border {
  content: "";
  /* margin-left: 30px;
  margin-right: 30px; */
  margin-bottom: 2px;
  width: 70%;
  border-bottom: 1px #d1d3e2 solid !important;
  margin-left: auto;
  margin-right: auto;
}

/*Make product information tab responsive*/
@media (max-width: 1095px) {
  .prod-info-data-container {
    flex-direction: column !important;
    margin-bottom: 0 !important;
  }

  .prod-info-data-container div,
  .comments-container div {
    max-width: 100%;
    margin-bottom: 0.25rem !important;
  }

  .comments-container {
    flex-direction: column;
  }
}

/*Make header responsive*/
@media (max-width: 1150px) {
  .main-container {
    flex-direction: column !important;
  }

  .left-section {
    margin-bottom: 1rem;
    width: 100%;
  }

  .first-header-row {
    flex-direction: column !important;
    gap: 0.5rem;
  }
}

@media (max-width: 768px) {
  .header-row,
  .first-header-row {
    flex-direction: column !important;
    width: 100%;
    gap: 1rem;
  }
}

/*Make header first set of data responsive*/
@media (max-width: 575px) {
  .left-section {
    flex-direction: column !important;
    align-items: flex-start;
    min-width: 150px;
  }
}

.components-listing-table {
  .number-col {
    width: 3%;
  }

  .tps-col {
    width: 5%;
  }

  .component-col {
    width: 22%;
  }

  .material-col,
  .color-col,
  .decoration-col,
  .artwork-col {
    width: 15%;
  }

  .pcs-col,
  .mold-index-col {
    width: 10%;
  }
}

/*=============================================*/

.ag-cell[col-id="is_primary"] {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ag-floating-filter-button-button .fa.fa-filter {
  color: #e3e6f0;
}

.ag-sort-indicator-icon.ag-sort-order {
  display: none;
}

.checkbox {
  accent-color: var(--gray-text-color);
  width: 15px;
  height: 15px;
}

.ag-wrapper.ag-input-wrapper.ag-checkbox-input-wrapper {
  width: 15px;
  height: 15px;
  background-color: transparent;
  border-radius: 3px;
}

.ag-checkbox-input-wrapper::after,
.ag-checkbox-input-wrapper.ag-checked::after {
  content: none;
}

.ag-wrapper.ag-input-wrapper.ag-checkbox-input-wrapper input[type="checkbox"] {
  -webkit-appearance: checkbox;
  /* width: 15px;
  height: 15px; */
  accent-color: var(--gray-text-color);
  opacity: unset;
  outline: none;
  /* width: unset;
  height: unset; */
}

.ag-theme-alpine .ag-layout-auto-height .ag-center-cols-clipper,
.ag-theme-alpine .ag-layout-auto-height .ag-center-cols-container,
.ag-theme-alpine .ag-layout-print .ag-center-cols-clipper,
.ag-theme-alpine .ag-layout-print .ag-center-cols-container,
.ag-theme-alpine-dark .ag-layout-auto-height .ag-center-cols-clipper,
.ag-theme-alpine-dark .ag-layout-auto-height .ag-center-cols-container,
.ag-theme-alpine-dark .ag-layout-print .ag-center-cols-clipper,
.ag-theme-alpine-dark .ag-layout-print .ag-center-cols-container {
  min-height: 50px;
}

.ag-selection-checkbox {
  margin-right: 0px !important;
  padding: 15px;
}

.fixed-checkbox-height .checkbox {
  height: 20px;
  width: 20px;
}

.icon-link {
  color: var(--gray-text-color);
}

/* --- Filter Form Controls  */

.product-number-col {
  min-width: 130px;
  max-width: 150px;
}

.top-filter-fields {
  padding: 0 20px;
}

.smaller-col-3 {
  flex: 0 0 22.24%;
  max-width: 22.24%;
}

.form-control.is-invalid {
  background-image: none;
  padding-right: 0;
}

.form-control::placeholder {
  font-size: 12px;
}

.react-select__placeholder {
  font-size: 12px;
}

.is-invalid .react-select__control {
  border-color: #e74a3b !important;
}

.only-counter-feedback {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
}

/* --- Global Info Tabs */

.global-info-tab .nav-tabs .nav-link,
.product-information-tabs-container .nav-tabs .nav-link {
  color: var(--black-text-color);
  border-color: transparent;
  padding: 0.25rem 0.75rem;
}

.global-info-tab .nav-tabs .nav-item.show .nav-link,
.global-info-tab .nav-tabs .nav-link.active,
.product-information-tabs-container .nav-tabs .nav-item.show .nav-link,
.product-information-tabs-container .nav-tabs .nav-link.active {
  font-weight: bold;
  color: #5a5c69;
  border: 1px solid var(--black-text-color);
  border-bottom: 0px;
  border-radius: 6px 6px 0px 0px;
}

/* --- Tooltip */
.tooltip-span {
  margin-left: 10px;
}

.tooltip-icon {
  color: #4e73df;
  /* margin-bottom: 2px; */
}

.changes-tooltip {
  display: flex;
  text-align: center;
  background-color: #fff;
  border: 1px solid #4e73df;
  border-radius: 6px;
  color: var(--gray-text-color);
  padding: 10px;
  max-width: 200px;
}

.changes-tooltip p {
  margin-bottom: 0;
}

.__react_component_tooltip {
  /* margin-left: 120px !important;
  margin-top: -10px !important; */
  /* max-width: 210px; */
  text-align: center;
  border-color: #4e73df !important;
  border-radius: 6px !important;
  font-size: 12px !important;
  color: var(--gray-text-color) !important;
  padding: 8px !important;
}

.__react_component_tooltip::before,
.__react_component_tooltip::after {
  content: none !important;
}
.react-tooltip-arrow {
  border-color: #2e59d9 !important;
  border-right-width: 1px;
  border-right-style: solid;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}

/* -----Custom Pagination */

.pagination {
  color: #333333;
  font-size: 14px;
}

.pagination .page-button {
  padding: 0;
  display: inline-block;
  border: none;
  background-color: #fff;
  margin-left: 8px;
}

.pagination .page-navigation {
  height: 33px;
}

.pagination .page-num {
  text-align: center;
  width: 28px;
  height: 28px;
  min-width: fit-content;
  font-size: 12px;
}

.pagination .page-button.page-num.selected {
  border-radius: 50%;
  width: 28px;
  height: 28px;
  background-color: var(--gray-text-color);
  color: #fff;
  border: none;
  font-size: 12px;
}

/* ----- Navigation */
.go-back-btn {
  cursor: pointer;
}

.navbar {
  box-shadow: 0 0.2rem 0.4rem 0 rgba(58, 59, 69, 0.15) !important;
}

.navbar,
.navbar-nav {
  z-index: 100;
}

/* .sidebar,
.sidebar-toggled {
  overflow-y: overlay !important;
  overflow-x: hidden !important;
  min-height: 100vh;
  max-height: 100vh;
  position: fixed;
} */

.sidebar {
  height: 100%;
}

/*Scrollbar of sidebar*/
ul::-webkit-scrollbar {
  width: 8px;
}

.sidebar-dark .nav-item .nav-link svg {
  color: rgba(255, 255, 255, 0.3);
}

.sidebar-dark .nav-item.active .nav-link svg {
  color: #ffff;
}

.sidebar-dark hr.sidebar-divider {
  border-top: 1px solid white;
}

/* #content-wrapper {
  &.padding-open {
    padding-left: 14rem;
  }

  &.padding-collapsed {
    padding-left: 6.5rem;
  }

  @media screen and (max-width: 767px) {
    &.padding-open {
      padding-left: 6.5rem;
    }

    &.padding-collapsed {
      padding-left: 0;
    }
  }
} */

/* ------ Notifications */

.notification-container {
  max-height: 240px;
  overflow-y: auto;
}

/* .notification-container svg, */
.notification-container span {
  color: var(--gray-text-color);
}

.notification-container .icon-circle {
  height: 1.5rem;
  width: 1.5rem;
  border-radius: 50%;
  color: var(--gray-text-color);
}

.notification-container .icon-circle.bordered {
  border: 2px solid;
}

.notification-container .icon-circle.success {
  border-color: #95c62e;
  color: #95c62e;
}

.notification-container .icon-circle.pending {
  border-color: #ffc107;
  color: #ffc107;
}

.notification-container .icon-circle.fail {
  border-color: #d71f15;
  color: #d71f15;
}

.dropdown-item:focus,
.dropdown-item:hover {
  color: var(--bs-dropdown-link-hover-color);
  background-color: var(--bs-dropdown-link-hover-bg);
  cursor: pointer;
}

/* ---- Modal styled */

.custom-modal {
  background-color: #ffffff;
  border: 1px solid #d1d3e2;
  border-radius: 6px;
  margin-left: 4rem;
  min-height: fit-content;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  min-width: 50%;
}

.custom-modal.mold-comps {
  width: 70%;
  max-height: 80%;
  overflow-y: overlay;
}

.custom-modal.tcc-business-composition {
  width: 90%;
  max-height: 80%;
  overflow-y: overlay;
}

/* Barcode Modal */
.custom-modal.barcode-img-modal {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  min-width: 600px;
}

.custom-modal.popup {
  max-width: 300px;
  min-width: 200px;
}

.btn.btn-success.upload-success-badge {
  border-color: #98c830;
  background-color: #98c830;
}

.upload-error-badge.disabled,
.upload-success-badge.disabled {
  opacity: 1;
}

.mold-comps .molds-table-container {
  /* height: 300px; */
  overflow-y: auto;
  border-top: 1px solid #e3e6f0;
}

.molds-table-container th {
  position: sticky;
  top: -1px;
  background-color: #fff;
}

.custom-overlay {
  background-color: rgba(230, 230, 230, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 400ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  &.small-spinner {
    width: unset;

    img {
      max-height: 50px;
    }
  }

  &.medium-spinner {
    width: unset;

    img {
      max-height: 80px;
    }
  }
}

/* For pdf */
.react-pdf__Page {
  margin-bottom: 10px;
}

.react-pdf__Page__annotations.annotationLayer {
  padding: 20px;
}

.react-pdf__Page__canvas {
  margin: 0 auto;
}

.scroll-to-top {
  background-color: #4e72df;
  border-radius: 50%;
  border: none;
  margin-right: 20px;
}

.fixed-width-button {
  width: 90px !important;
}

.table-link {
  cursor: pointer;
  color: #4e73df;
}

.table-link:hover {
  text-decoration: underline;
}

/* ---- User Guide Styles */
.userguide-page {
  background-color: #fff;
  color: #172b4d;
  padding: 50px;
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
}

.guide {
  width: 70%;
  margin: 0 auto;
}

.guide .guide-content {
  background-color: #fff;
}

.guide img {
  border: 1px solid rgb(230, 228, 228);
  border-radius: 6px;
  display: block;
  width: 100%;
  margin: 20px;
}

.guide .image.image-medium {
  width: 70%;
}

.guide .image.image-small {
  width: 30%;
}

.guide blockquote p {
  display: block;
  font-size: 16px;
  text-align: center;
  color: var(--gray-text-color);
  margin-bottom: 20px;
  margin-top: 5px;
}

.guide h4 {
  margin: 60px 0 10px 0;
}

.guide h3 {
  margin: 60px 0 10px 0;
}

.guide h2 {
  margin: 70px 0 10px 0;
  font-weight: 600;
}

.guide h1 {
  margin: 50px 0 20px 0;
  font-size: 35px;
}

.fw-bolder {
  font-weight: 900 !important;
}

/* ---- Custom Cell Renderers */

@media screen and (max-width: 968px) {
  .custom-modal.mass-update {
    height: unset;
  }

  .guide {
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .custom-modal {
    transform: unset;
    margin-left: 0px;
    left: 10%;
    right: 10%;
    top: 10%;
  }

  .guide .image.image-medium {
    width: 90%;
  }

  .guide .image.image-small {
    width: 70%;
  }
}

.vh-80 {
  height: 80vh;
}

.ag-root {
  min-height: 120px;
}

a.text-decoration-none {
  color: inherit;
}
